import * as React from "react"
import type { HeadFC } from "gatsby"
import styled from "@emotion/styled"
import { gs } from "../components/styling/GlobalStyles"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { SectionWrapper } from "../components/Sections"
import { HeadingTitle } from "../components/styling/Heading"
import { Button } from "../components/styling/Button"
import { PageProps } from "gatsby"

const title = "Contact"

const Form = styled.form`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
`
const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: none;
  background-color: transparent;
  color: ${gs.color.white};
  padding: 5px 10px;
  font-size: ${gs.size.font.lg};
  &::placeholder {
    color: ${gs.color.secondary};
  }
`
const DivInputWrapper = styled.div`
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  &::before {
    pointer-events: none;
    z-index: 2;
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: 100%;
    top: 0;
    left: 0;
    border: 0 solid ${gs.color.underline()};
    border-width: 3px 0 0 3px;
  }
  &::after {
    pointer-events: none;
    z-index: 2;
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: 100%;
    bottom: 0;
    right: 0;
    border: 0 solid ${gs.color.underline()};
    border-width: 0 3px 3px 0;
  }
`
const DivField = styled.div`
  //max-width: 720px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  flex-grow: 1;
  gap: 5px;
`
const DivTextAreaWrap = styled(DivInputWrapper)`
  padding: 5px 5px 0 5px;
`
const Textarea = styled.textarea`
  box-sizing: border-box;
  resize: vertical;
  ${gs.font.default};
  background-color: transparent;
  width: 100%;
  border: 0;
  padding: 10px;
  color: ${gs.color.white};
  &::placeholder {
    color: ${gs.color.secondary};
  }
  font-size: ${gs.size.font.md};
  @media all and (min-width: ${gs.size.screen.md}) {
    font-size: ${gs.size.font.lg};
  }
`
const Label = styled.label`
  ${gs.font.mono};
  color: ${gs.color.primary};
  width: 100%;
  font-size: ${gs.size.font.md};
  @media all and (min-width: ${gs.size.screen.md}) {
    font-size: ${gs.size.font.md};
  }
`
const ButtonSubmit = styled(Button)`
  font-weight: 700;
  font-size: ${gs.size.font.xl};
  @media all and (min-width: ${gs.size.screen.lg}) {
    font-size: ${gs.size.font.xxl};
  }
  &:disabled {
    color: ${gs.color.secondary};
    cursor: default;
  }
`
const DivNameEmail = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  @media all and (min-width: ${gs.size.screen.md}) {
    flex-wrap: nowrap;
    width: 100%;
  }
`
const DivThanks = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-size: ${gs.size.font.md};
  color: ${gs.color.primary};
`

const initialState = {
  email: "",
  name: "",
  subject: "",
  message: "",
  valid: false,
  submitted: false,
}
function isValid(name: string, email: string, message: string): boolean {
  if (name !== "" && email !== "" && message !== "") {
    return true
  } else {
    return false
  }
}

enum ActionType {
  NAME,
  EMAIL,
  SUBJECT,
  MESSAGE,
  SUBMIT,
}
interface State {
  name: string
  email: string
  subject: string
  message: string
  valid: boolean
  submitted: boolean
}
type Action = {
  type: ActionType
  payload: string
}

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.NAME: {
      return {
        name: action.payload,
        email: state.email,
        subject: state.subject,
        message: state.message,
        valid: isValid(action.payload, state.email, state.message),
        submitted: false,
      }
    }
    case ActionType.EMAIL: {
      return {
        name: state.name,
        email: action.payload,
        subject: state.subject,
        message: state.message,
        valid: isValid(state.name, action.payload, state.message),
        submitted: false,
      }
    }
    case ActionType.SUBJECT: {
      return {
        name: state.name,
        email: state.email,
        subject: action.payload,
        message: state.message,
        valid: isValid(state.name, state.email, state.message),
        submitted: false,
      }
    }
    case ActionType.MESSAGE: {
      return {
        name: state.name,
        email: state.email,
        subject: state.subject,
        message: action.payload,
        valid: isValid(state.name, state.email, action.payload),
        submitted: false,
      }
    }
    case ActionType.SUBMIT: {
      return {
        name: "",
        email: "",
        subject: "",
        message: "",
        valid: false,
        submitted: true,
      }
    }
    default: {
      throw Error("Unknown action: " + action.type)
    }
  }
}

const ContactPage = (props: PageProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <Layout pageprops={props}>
      <SectionWrapper
        id="Contact-Form"
        heading={
          <HeadingTitle
            name={title}
            subtitle="! Keyboard Shortcuts Are Disabled On This Page !"
            alert={true}
          />
        }
      >
        <form
          name="contact"
          data-netlify="true"
          netlify-honeypot="bot-field"
          hidden
        >
          <input type="text" name="name" />
          <input type="email" name="email" />
          <textarea name="message"></textarea>
        </form>
        <Form
          name="contact"
          onSubmit={(e) => {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                "form-name": "contact",
                name: state.name,
                email: state.email,
                message: state.message,
              }),
            })
              .then(() => console.log("Message Submitted!"))
              .catch((error) => alert(error))
            dispatch({
              type: ActionType.SUBMIT,
              payload: "",
            })
            e.preventDefault()
          }}
        >
          <input type="hidden" name="form-name" value="contact" />
          {state.submitted && <DivThanks>Thanks for reaching out!</DivThanks>}
          <DivNameEmail>
            <DivField>
              <Label htmlFor="name">Name*</Label>
              <DivInputWrapper>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  placeholder="Jay Doe"
                  value={state.name}
                  onChange={(e) => {
                    dispatch({
                      type: ActionType.NAME,
                      payload: e.target.value,
                    })
                  }}
                />
              </DivInputWrapper>
            </DivField>
            <DivField>
              <Label htmlFor="email">Email*</Label>
              <DivInputWrapper>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  placeholder="example@email.com"
                  value={state.email}
                  onChange={(e) => {
                    dispatch({
                      type: ActionType.EMAIL,
                      payload: e.target.value,
                    })
                  }}
                />
              </DivInputWrapper>
            </DivField>
          </DivNameEmail>
          {/* <DivField>
            <Label htmlFor="subject">Subject*</Label>
            <DivInputWrapper>
              <Input
                type="text"
                name="subject"
                id="subject"
                aria-label="subject"
                placeholder="Subjects Are Weird, Write What You Want"
                value={state.subject}
                onChange={(e) => {
                  dispatch({
                    type: ActionType.SUBJECT,
                    payload: e.target.value,
                  })
                }}
              />
            </DivInputWrapper>
          </DivField> */}
          <DivField>
            <Label htmlFor="message">Message*</Label>
            <DivTextAreaWrap>
              <Textarea
                name="message"
                id="message"
                rows={5}
                aria-label="message"
                placeholder="A (preferably) nice message like: Hi Violet! I think your work is pretty neat. We should chat about working together."
                value={state.message}
                onChange={(e) => {
                  dispatch({
                    type: ActionType.MESSAGE,
                    payload: e.target.value,
                  })
                }}
              />
            </DivTextAreaWrap>
          </DivField>
          <ButtonSubmit type="submit" name="submit" disabled={!state.valid}>
            Submit
          </ButtonSubmit>
        </Form>
      </SectionWrapper>
    </Layout>
  )
}

export default ContactPage

export const Head: HeadFC = () => <Seo title={title} />
